:root {
  --bg: #fff;
  --blob: #ad7d66;
  --blob-shadow: #ad7d66;
}

/* #preloader {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: column;
  z-index: 99999;
  justify-content: center;
  align-items: center;
  background: var(--bg);
} */

.blobs {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><defs><filter id='goo'><feGaussianBlur in='SourceGraphic' stdDeviation='10' result='blur'/><feColorMatrix in='blur' mode='matrix' values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7' result='goo'/><feBlend in='SourceGraphic' in2='goo'/></filter></defs></svg>#goo");
  width: 300px;
  height: 50px;
  position: relative;
  overflow: hidden;
  border-radius: 70px;
  transform-style: preserve-3d;
  margin: 0 auto;
}

.blobs .blob-center {
  transform-style: preserve-3d;
  position: absolute;
  background-color: var(--blob);
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  transform-origin: left top;
  transform: scale(0.9) translate(-50%, -50%);
  animation: blob-grow linear 3.2s infinite;
  border-radius: 50%;
}

.blob-center {
  z-index: 9999;
  /* box-shadow: 0 0 15px var(--blob-shadow); */
}

.blob {
  z-index: 0;
  position: absolute;
  background-color: var(--blob);
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  animation: blobs ease-out 3.2s infinite;
  transform: scale(0.9) translate(-50%, -50%);
  /* box-shadow: 0 0 15px var(--blob-shadow); */
  transform-origin: center top;
  opacity: 0;
}

.blob:nth-child(1) {
  animation-delay: calc(1 * 0.2s);
}

.blob:nth-child(2) {
  animation-delay: calc(2 * 0.2s);
}

.blob:nth-child(3) {
  animation-delay: calc(3 * 0.2s);
}

.blob:nth-child(4) {
  animation-delay: calc(4 * 0.2s);
}

.blob:nth-child(5) {
  animation-delay: calc(5 * 0.2s);
}

.blob:nth-child(6) {
  animation-delay: calc(6 * 0.2s);
}

@keyframes blobs {
  0% {
    opacity: 0;
    transform: scale(0) translate(calc(-330px - 50%), -50%);
  }

  1% {
    opacity: 1;
  }

  35%,
  65% {
    opacity: 1;
    transform: scale(0.9) translate(-50%, -50%);
  }

  99% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(0) translate(calc(330px - 50%), -50%);
  }
}

@keyframes blob-grow {
  0%,
  39% {
    transform: scale(0) translate(-50%, -50%);
  }

  40%,
  42% {
    transform: scale(1, 0.9) translate(-50%, -50%);
  }

  43%,
  44% {
    transform: scale(1.2, 1.1) translate(-50%, -50%);
  }

  45%,
  46% {
    transform: scale(1.3, 1.2) translate(-50%, -50%);
  }

  47%,
  48% {
    transform: scale(1.4, 1.3) translate(-50%, -50%);
  }

  52% {
    transform: scale(1.5, 1.4) translate(-50%, -50%);
  }

  54% {
    transform: scale(1.7, 1.6) translate(-50%, -50%);
  }

  58% {
    transform: scale(1.8, 1.7) translate(-50%, -50%);
  }

  68%,
  70% {
    transform: scale(1.7, 1.5) translate(-50%, -50%);
  }

  78% {
    transform: scale(1.6, 1.4) translate(-50%, -50%);
  }

  80%,
  81% {
    transform: scale(1.5, 1.4) translate(-50%, -50%);
  }

  82%,
  83% {
    transform: scale(1.4, 1.3) translate(-50%, -50%);
  }

  84%,
  85% {
    transform: scale(1.3, 1.2) translate(-50%, -50%);
  }

  86%,
  87% {
    transform: scale(1.2, 1.1) translate(-50%, -50%);
  }

  90%,
  91% {
    transform: scale(1, 0.9) translate(-50%, -50%);
  }

  92%,
  100% {
    transform: scale(0) translate(-50%, -50%);
  }
}
